import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { GetPassRes } from "../../../StateManagement/Reducers/PreLoginState";

import LoginLeft from "../LoginLeft";

import {
  Button,
  Image,
  Form,
  FormGroup,
  FormLabel,
  Div,
  Description,
} from "../../StyledComponents";
import {
  Title,
  Container,
  Input,
  TitleImage,
  ImageContainer,
} from "../StyledComponents";

import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { FaRegUser } from "react-icons/fa";
import Logo from "../../../Assets/images/Logo.png";
import password_icon from "../../../Assets/images/password_setup_icon.png";

const SetupPassword = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const { state } = useLocation();

  const [PasswordShow1, setPasswordShow1] = useState(true);
  const [PasswordShow, setPasswordShow] = useState(true);
  const [passErr, setPassErr] = useState(false);
  const [misMatch, setMissMatch] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const [btndis, setbtndis] = useState(false);
  const [pwd1, setPwd1] = useState("");
  const [pwd2, setPwd2] = useState("");
  const [email] = useState(state ? state.type.email : "");
  const passRes = useSelector((state) => state.PreLogin.passWordRes);

  const Password = (e) => {
    setMissMatch(false);
    setPwd1(e.target.value);
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    if (strongRegex.test(e.target.value)) {
      setPassErr(false);
    } else {
      setPassErr(true);
      setErrMsg(
        "Password must contain Atleast  1 upperlower 1 number 1 symbol and minimum 8 characters length "
      );
    }
  };

  const ConformPassword = (e) => {
    setMissMatch(false);
    setPwd2(e.target.value);
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    if (strongRegex.test(e.target.value)) {
      setMissMatch(false);
    } else {
      setMissMatch(true);
      setErrMsg(
        "Password must contain Atleast  1 upperlower 1 number 1 symbol and minimum 8 characters length "
      );
    }
  };

  const handleSave = (e) => {
    setbtndis(true);
    setTimeout(() => {
      setbtndis(false);
    }, 1000);
    e.preventDefault();

    if (pwd1 === "") {
      setPassErr(true);
      setErrMsg("Please enter the password");
    }

    if (pwd2 === "") {
      setMissMatch(true);
      setErrMsg("Please enter the password");
    }

    if (passErr || misMatch) return false;

    if (pwd1 === pwd2) {
      dispatch(
        GetPassRes({
          type: { new_password: pwd1, con_password: pwd2, email: email },
        })
      );
    } else {
      setMissMatch(true);
      setErrMsg("Password Mismatched");
    }
  };

  useEffect(() => {
    if (passRes === "Success") {
      Navigate("/Login");
    } else if (passRes === "Failure") {
      setMissMatch(true);
      setErrMsg("Password saving failed");
    }
  }, [passRes]);

  return (
    <Container Flex height="100vh" minWidth="1024px">
      <Div width="50%" backColor="#F8FAFB" position="relative">
        <LoginLeft />
      </Div>
      <Div width="50%" backColor="#FFFFFF" overflowY="auto">
        <Div width="500px" textAlign="center" margin="0px auto">
          <ImageContainer marginTop="20px" marginBottom="10px">
            <Image
              loading="lazy"
              src={Logo}
              alt="avalon-ehr-logo"
              height="40px"
              width="140px"
              objectFit="contain"
            />
          </ImageContainer>
          <TitleImage
            src={password_icon}
            alt="Setup password"
            width="124px"
            height="124px"
            marginTop="2%"
          />
          <Title
            color="#000000"
            fontWeight="700"
            fontSize="36px"
            lineHeight="44px"
            marginBottom="12px"
          >
            Set Your Password
          </Title>
          <Description
            alignItems="center"
            color=" #667085"
            marginBottom="32px"
            fontSize="20px"
            lineHeight="27px"
          >
            Use the email your practitioner has on file to sign in—no password
            necessary.
          </Description>
          <FormGroup marginBottom="32px" display="grid" width="100%">
            <Button
              Appointment
              cursor="not-allowed"
              background="#F9F8F8"
              height="48px"
              color="#667085"
            >
              <FaRegUser style={{ marginRight: 8 }} /> {email}
            </Button>
          </FormGroup>
          <Form>
            <FormGroup marginBottom="19px" display="grid" width="100%">
              <FormLabel
                marginBottom="6px"
                textAlign="left"
                color="#7D8592"
                fontWeight="600"
                fontSize="14px"
                lineHeight="24px"
                letterSpacing="1px"
              >
                Password
              </FormLabel>
              <Input
                placeholder="Enter password"
                autoComplete="off"
                type={PasswordShow ? "password" : "text"}
                value={pwd1}
                onChange={(e) => Password(e)}
              />
              {PasswordShow ? (
                <FaRegEye
                  style={{
                    position: "absolute",
                    right: 15,
                    top: "43px",
                    cursor: "pointer",
                    color: "#7d8592",
                    fontSize: 20,
                  }}
                  onClick={() => setPasswordShow((Prevstate) => !Prevstate)}
                />
              ) : (
                <FaRegEyeSlash
                  style={{
                    position: "absolute",
                    right: 15,
                    top: "43px",
                    cursor: "pointer",
                    color: "#7d8592",
                    fontSize: 20,
                  }}
                  onClick={() => setPasswordShow((Prevstate) => !Prevstate)}
                />
              )}
              {passErr && (
                <FormLabel
                  marginBottom="0px"
                  textAlign="left"
                  fontSize="14px"
                  color="red"
                  fontWeight="400"
                  fontFamily="inter"
                  lineHeight="24px"
                  marginTop="5px"
                >
                  {errMsg}
                </FormLabel>
              )}
            </FormGroup>
            <FormGroup marginBottom="19px" display="grid" width="100%">
              <FormLabel
                marginBottom="6px"
                textAlign="left"
                color="#7D8592"
                fontWeight="600"
                fontSize="14px"
                lineHeight="24px"
                letterSpacing="1px"
              >
                Confirm Password
              </FormLabel>
              <Input
                placeholder="Re-enter password"
                autoComplete="off"
                type={PasswordShow1 ? "password" : "text"}
                value={pwd2}
                onChange={(e) => ConformPassword(e)}
              />
              {PasswordShow1 ? (
                <FaRegEye
                  style={{
                    position: "absolute",
                    right: 15,
                    top: "43px",
                    cursor: "pointer",
                    color: "#7d8592",
                    fontSize: 20,
                  }}
                  onClick={() => setPasswordShow1((Prevstate) => !Prevstate)}
                />
              ) : (
                <FaRegEyeSlash
                  style={{
                    position: "absolute",
                    right: 15,
                    top: "43px",
                    cursor: "pointer",
                    color: "#7d8592",
                    fontSize: 20,
                  }}
                  onClick={() => setPasswordShow1((Prevstate) => !Prevstate)}
                />
              )}
              {misMatch && (
                <FormLabel
                  marginBottom="0px"
                  textAlign="left"
                  fontSize="14px"
                  color="red"
                  fontWeight="400"
                  fontFamily="inter"
                  lineHeight="24px"
                  marginTop="5px"
                >
                  {errMsg}
                </FormLabel>
              )}
            </FormGroup>

            <FormGroup marginBottom="32px" display="grid" width="100%">
              <Button
                background="#2C7BE5"
                hoverBackColor="#005FB2"
                className="save_active"
                height="48px"
                disabled={btndis}
                onClick={(e) => handleSave(e)}
              >
                Save password
              </Button>
            </FormGroup>
          </Form>
        </Div>
      </Div>
    </Container>
  );
};

export default SetupPassword;
