import React from "react";
import { useDispatch } from "react-redux";
import { setMode } from "../../../StateManagement/Reducers/PreLoginState";
import { setEmailRes } from "../../../StateManagement/Reducers/PreLoginState";
import { NormalImage, Div } from "../../StyledComponents";
import { Container, ImageContainer, Image } from "../StyledComponents";
import { TitleBlock, Title, SubTitle, ClientButton, Status } from "./Styles";
import Logo from "../../../Assets/images/Logo.png";
import Login from "../../../Assets/images/login.png";
import Sign from "../../../Assets/images/SignUp.png";
import { Encrypt_Value } from "../../../MiddleWare/EncryptDecrypt";

const WelcomeRight = () => {
  const dispatch = useDispatch();
  const handleMode = (a) => {
    localStorage.removeItem("Mode");
    if (a === "1") {
      let mode = "new";
      dispatch(setMode("new"));
      localStorage.setItem("Mode", Encrypt_Value(mode, "westside"));
      dispatch(setEmailRes({ status: "", output: "" }));
    } else {
      let mode = "old";
      dispatch(setMode("old"));
      localStorage.setItem("Mode", Encrypt_Value(mode, "westside"));
      dispatch(setEmailRes({ status: "", output: "" }));
    }
  };

  return (
    <Container width="100%">
      <Div width="50%" marginTop="30px" margin="auto" textAlign="center">
        <ImageContainer marginBottom="20px">
          <Image
            loading="lazy"
            src={Logo}
            height="40px"
            width="140px"
            objectFit="contain"
            alt="avalon-ehr-logo"
          />
        </ImageContainer>
        <TitleBlock>
          <Title color="#1F28EB">Welcome back!&nbsp;</Title>
          <Title color="#000000">Nice to see you again</Title>
        </TitleBlock>
        <SubTitle>
          Use the email your practitioner has on file to sign in—no password
          necessary.
        </SubTitle>
        <Div display=" flex" justifyContent="center" marginTop=" 44px">
          <ClientButton
            marginright="40px"
            to="/InstantSignIn/New"
            exact="true"
            onClick={() => handleMode("1")}
          >
            <NormalImage
              loading="lazy"
              src={Sign}
              alt="Sign-Up Image"
              width="186px"
              height="138px"
            ></NormalImage>
            <Status>I’M A NEW CLIENT</Status>
          </ClientButton>
          <ClientButton
            to="/InstantSignIn/Old"
            exact="true"
            onClick={() => handleMode("2")}
          >
            <NormalImage
              loading="lazy"
              src={Login}
              alt="Login-Image"
              width="186px"
              height="138px"
              id="wel_img-ext"
            />
            <Status>EXISTING CLIENT</Status>
          </ClientButton>
        </Div>
      </Div>
    </Container>
  );
};

export default WelcomeRight;
