import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { GetPassRes } from "../../../StateManagement/Reducers/PreLoginState";

import LoginLeft from "../LoginLeft";

import {
  Button,
  Image,
  Form,
  FormGroup,
  FormLabel,
  Div,
} from "../../StyledComponents";
import {
  Title,
  Container,
  Input,
  TitleImage,
  ImageContainer,
} from "../StyledComponents";
import Logo from "../../../Assets/images/Logo.png";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import password_icon from "../../../Assets/images/password_setup_icon.png";
import {
  getCredentials,
  LoginPass,
} from "../../../StateManagement/Reducers/LoginState";

const SetupPassword = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const Navigate = useNavigate();

  const [email] = useState(state ? state.type.email : "");
  const [PasswordShow, setPasswordShow] = useState(true);
  const [passErr, setPassErr] = useState(false);
  const [misMatch, setMissMatch] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const [pwd1, setPwd1] = useState("");
  const [pwd2, setPwd2] = useState("");
  const [PasswordShow1, setPasswordShow1] = useState(true);
  const [btndis, setbtndis] = useState(false);
  const passRes = useSelector((state) => state.PreLogin.passWordRes);
  const LoginResponse = useSelector((state) => state.Login.LoginRes);
  const HandleSave = (e) => {
    setbtndis(true);
    setTimeout(() => {
      setbtndis(false);
    }, 1000);
    e !== "skip" && e.preventDefault();

    if (passErr || misMatch) return;

    if (pwd1 === "") {
      setPassErr(true);
      setErrMsg("Please enter the password");
    }

    if (pwd2 === "") {
      setMissMatch(true);
      setErrMsg("Please enter the password");
    }
    if (pwd1 === pwd2) {
      dispatch(
        getCredentials({
          UserName: email,
          Password: pwd1,
          source: "forgot_password",
        })
      );

      if (
        Object.keys(LoginResponse).length > 0 &&
        LoginResponse["status"] &&
        LoginResponse["status"] !== ""
      ) {
        if (LoginResponse.status === "LOGIN_SUCCESS") {
          setMissMatch(true);

          setErrMsg("Enter different password");
        } else if (LoginResponse.status === "LOGIN_FAILURE") {
          dispatch(
            GetPassRes({
              type: { new_password: pwd1, con_password: pwd2, email: email },
            })
          );

          dispatch(LoginPass({ status: "", output: "" }));
          Navigate("/Login");
        }
      }
    } else {
      setMissMatch(true);
      setErrMsg("Password mismatch");
    }
  };

  const PasswordCheck = (e) => {
    setMissMatch(false);
    setPwd1(e.target.value);

    if (
      Object.keys(LoginResponse).length > 0 &&
      LoginResponse["status"] &&
      LoginResponse["status"] !== ""
    ) {
      dispatch(LoginPass({ status: "", output: "" }));
    }
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    if (strongRegex.test(e.target.value)) {
      setPassErr(false);
    } else {
      setPassErr(true);
      setErrMsg(
        "Password must contain at least 8 characters, including UPPER/lowercase, symbol and numbers "
      );
    }
  };

  const ConformPasswordCheck = (e) => {
    setMissMatch(false);
    if (
      Object.keys(LoginResponse).length > 0 &&
      LoginResponse["status"] &&
      LoginResponse["status"] !== ""
    ) {
      dispatch(LoginPass({ status: "", output: "" }));
    }
    setPwd2(e.target.value);
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    if (strongRegex.test(e.target.value)) {
      setMissMatch(false);
    } else {
      setMissMatch(true);
      setErrMsg(
        "Password must contain at least 8 characters, including UPPER/lowercase, symbol and numbers "
      );
    }
  };

  useEffect(() => {
    if (passRes === "Success") {
      Navigate("/Login");
    } else if (passRes === "Failure") {
      setMissMatch(true);
      setErrMsg("passWord saving failed");
    }
  }, [passRes]);

  useEffect(() => {
    if (Object.keys(LoginResponse).length > 0) {
      HandleSave("skip");
    }
  }, [LoginResponse]);

  return (
    <Container Flex height="100vh" minWidth="1024px">
      <Div width="50%" backColor="#F8FAFB" position="relative">
        <LoginLeft />
      </Div>
      <Div width="50%" backColor="#FFFFFF">
        <Div width="100%">
          <Div width="500px" textAlign="center" margin="auto">
            <ImageContainer marginTop="15px" marginBottom="15px">
              <Image
                loading="lazy"
                alt="avalon-ehr-logo"
                src={Logo}
                height="40px"
                width="140px"
              />
            </ImageContainer>

            <TitleImage
              src={password_icon}
              alt="Setup password"
              width="124px"
              height="124px"
              marginTop="5%"
            ></TitleImage>
            <Title
              color="#000000"
              fontWeight="700"
              fontSize="36px"
              lineHeight="44px"
              marginBottom="12px"
            >
              Reset Your Password
            </Title>
            <FormGroup marginBottom="32px" display="grid" width="100%">
              Your ID has been verified! Set your new password here
            </FormGroup>
            <Form>
              <FormGroup marginBottom="19px" display="grid" width="100%">
                <FormLabel
                  marginBottom="6px"
                  textAlign="left"
                  color="#7D8592"
                  fontWeight="600"
                  fontSize="14px"
                  lineHeight="24px"
                  letterSpacing="1px"
                >
                  Password
                </FormLabel>
                <Input
                  placeholder="Enter password"
                  autoComplete="off"
                  type={PasswordShow ? "password" : "text"}
                  value={pwd1}
                  onChange={(e) => PasswordCheck(e)}
                />
                {PasswordShow ? (
                  <FaRegEye
                    style={{
                      position: "absolute",
                      right: 15,
                      top: "43px",
                      cursor: "pointer",
                      color: "#7d8592",
                      fontSize: 20,
                    }}
                    onClick={() => {
                      setPasswordShow((Prevstate) => !Prevstate);
                    }}
                  />
                ) : (
                  <FaRegEyeSlash
                    style={{
                      position: "absolute",
                      right: 15,
                      top: "43px",
                      cursor: "pointer",
                      color: "#7d8592",
                      fontSize: 20,
                    }}
                    onClick={() => {
                      setPasswordShow((Prevstate) => !Prevstate);
                    }}
                  />
                )}
                {passErr && (
                  <FormLabel
                    marginBottom="0px"
                    textAlign="left"
                    fontSize="14px"
                    color="red"
                    fontWeight="400"
                    fontFamily="inter"
                    lineHeight="24px"
                    marginTop="5px"
                  >
                    {errMsg}
                  </FormLabel>
                )}
              </FormGroup>
              <FormGroup marginBottom="19px" display="grid" width="100%">
                <FormLabel
                  marginBottom="6px"
                  textAlign="left"
                  color="#7D8592"
                  fontWeight="600"
                  fontSize="14px"
                  lineHeight="24px"
                  letterSpacing="1px"
                >
                  Confirm Password
                </FormLabel>
                <Input
                  placeholder="Re-enter password"
                  autoComplete="off"
                  type={PasswordShow1 ? "password" : "text"}
                  value={pwd2}
                  onChange={(e) => ConformPasswordCheck(e)}
                />
                {PasswordShow1 ? (
                  <FaRegEye
                    style={{
                      position: "absolute",
                      right: 15,
                      top: "43px",
                      cursor: "pointer",
                      color: "#7d8592",
                      fontSize: 20,
                    }}
                    onClick={() => {
                      setPasswordShow1((Prevstate) => !Prevstate);
                    }}
                  />
                ) : (
                  <FaRegEyeSlash
                    style={{
                      position: "absolute",
                      right: 15,
                      top: "43px",
                      cursor: "pointer",
                      color: "#7d8592",
                      fontSize: 20,
                    }}
                    onClick={() => {
                      setPasswordShow1((Prevstate) => !Prevstate);
                    }}
                  />
                )}
                {misMatch && (
                  <FormLabel
                    marginBottom="0px"
                    textAlign="left"
                    fontSize="14px"
                    color="red"
                    fontWeight="400"
                    fontFamily="inter"
                    lineHeight="24px"
                    marginTop="5px"
                  >
                    {errMsg}
                  </FormLabel>
                )}
              </FormGroup>

              <FormGroup marginBottom="32px" display="grid" width="100%">
                <Button
                  background="#2C7BE5"
                  hoverBackColor="#005FB2"
                  className="save_active"
                  height="48px"
                  disabled={btndis}
                  onClick={(e) => HandleSave(e)}
                >
                  Save password
                </Button>
              </FormGroup>
            </Form>
          </Div>
        </Div>
      </Div>
    </Container>
  );
};

export default SetupPassword;
