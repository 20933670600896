import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { GetSignUp } from "../../../StateManagement/Reducers/PreLoginState";
import { setMode } from "../../../StateManagement/Reducers/PreLoginState";
import { setEmailRes } from "../../../StateManagement/Reducers/PreLoginState";

import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { FaRegUser } from "react-icons/fa";
import {
  DropDownDiv,
  DropdownImage,
  DropdownOptions,
  LinK,
  SelectInput,
} from "../../StyledComponents";
import { Span } from "../StyledComponents";

import {
  Button,
  Form,
  FormGroup,
  FormLabel,
  Image,
  Div,
} from "../../StyledComponents";
import { Container, Input, ImageContainer } from "../StyledComponents";
import { Title, SubTitle, FormGroupInline } from "./Styles";
import Logo from "../../../Assets/images/Logo.png";
import { DropDownStyle } from "../../Profile/EditProfile/styles";
import arrowIcon from "../../../Assets/images/arrowIcon.png";
import { getProvidersList } from "../../../StateManagement/Reducers/AppointmentsState";

const SignUpRight = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const { state } = useLocation();

  const [fnErr, setfnErr] = useState(false);
  const [lnErr, setLnErr] = useState(false);
  const [p1Err, setP1Err] = useState(false);
  const [p2Err, setP2Err] = useState(false);
  const [err, setErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [btndis, setbtndis] = useState(false);
  const [firstName, setFirstname] = useState("");
  const [lastName, setLastname] = useState("");
  const [PasswordShow, setPasswordShow] = useState(true);
  const [PasswordShow1, setPasswordShow1] = useState(true);
  const [email] = useState(state ? state.type.email : "");
  const [Password, setPassword] = useState("");
  const [Password1, setPassword1] = useState("");
  const [dropDown6, setDropDown6] = useState(false);
  const [providerID, setProviderID] = useState("");
  const [providerNm, setProviderNm] = useState("");
  const [proErr, setProErr] = useState(false);
  const Signup = useSelector((state) => state.PreLogin.signup);
  const providersList = useSelector(
    (state) => state.Appointments.providersList
  );
  const relationNoteRef = useRef(null);

  const EmailValidation = (e) => {
    setPassword(e.target.value);
    setP2Err(false);
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    if (strongRegex.test(e.target.value)) {
      setP1Err(false);
    } else {
      setP1Err(true);
      setErrMsg(
        "Password must contain at least 8 characters, including UPPER/lowercase, symbol and numbers "
      );
    }
  };

  const ConformEmail = (e) => {
    setPassword1(e.target.value);
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    if (strongRegex.test(e.target.value)) {
      setP2Err(false);
    } else {
      setP2Err(true);
      setErrMsg(
        "Password must contain at least 8 characters, including UPPER/lowercase, symbol and numbers "
      );
    }
  };

  const handleMode = (a) => {
    if (a === "2") {
      dispatch(setMode("old"));
      dispatch(setEmailRes({ status: "", output: "" }));
    }
  };

  const handleCreate = (e) => {
    setbtndis(true);
    setTimeout(() => {
      setbtndis(false);
    }, 1000);
    e.preventDefault();
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    var err = 0;
    if (!strongRegex.test(Password)) {
      setP2Err(true);
      err = 2;
    }
    if (!strongRegex.test(Password1)) {
      setP2Err(true);
      err = 2;
    }
    if (firstName === "") {
      setfnErr(true);
      err = 2;
    }
    if (lastName === "") {
      setLnErr(true);
      err = 2;
    }
    if (email === "") {
      return false;
    }
    if (Password === "") {
      setP1Err(true);
      err = 2;
    }
    if (Password1 === "") {
      setP2Err(true);
      err = 2;
    }
    if (Password !== Password1) {
      setErrMsg("PassWord Mismatch");
      setP2Err(true);
      err = 2;
    }
    if (providerID === "") {
      setProErr(true);
      err = 2;
    }

    if (err > 0) {
      return false;
    } else {
      dispatch(
        GetSignUp({
          fname: firstName,
          lname: lastName,
          email: email,
          new_password: Password,
          con_password: Password1,
          providerID: providerID,
        })
      );
    }
  };

  const Toggling6 = () => setDropDown6((prevState) => !prevState);

  const relationSelect = (r) => {
    setProviderNm(r.name);
    setProviderID(r.id);
    setDropDown6(false);
    setProErr(false);
  };

  useEffect(() => {
    if (Signup === "Signup_success") {
      setErr(false);
      Navigate("/Login");
    } else {
      if (Signup.length !== 0) {
        setErr(true);
        setErrMsg("signup-failed");
      }
    }
  }, [Signup]);

  useEffect(() => {
    dispatch(
      getProvidersList({
        allprovider: 1,
      })
    );
  }, []);

  return (
    <Container>
      <Div width="500px" margin="auto" resWidth="420px">
        <ImageContainer marginTop="20px" marginBottom="10px">
          <Image
            loading="lazy"
            src={Logo}
            alt="avalon-ehr-logo"
            height="40px"
            width="140px"
            objectFit="contain"
          />
        </ImageContainer>
        <Title marginTop="10%">Create new account</Title>
        <SubTitle>Welcome back! Nice to see you again</SubTitle>

        <Form>
          <FormGroupInline>
            <FormGroup marginBottom="24px" display="grid">
              <FormLabel
                marginBottom="8px"
                color="#7D8592"
                fontWeight="600"
                fontSize="14px"
                lineHeight="24px"
                letterSpacing="1px"
              >
                First Name
              </FormLabel>
              <Input
                placeholder="Enter Name"
                value={firstName}
                type="text"
                color="#7D8592"
                onChange={(e) => {
                  if (isNaN(e.target.value) || e.target.value === "") {
                    setfnErr(false);
                    setFirstname(e.target.value);
                  }
                }}
              />
              {fnErr && (
                <FormLabel
                  marginBottom="0px"
                  textAlign="left"
                  fontSize="14px"
                  color="red"
                  fontWeight="400"
                  lineHeight="24px"
                  marginTop="5px"
                  fontFamily="inter"
                >
                  First Name cannot be Empty
                </FormLabel>
              )}
              {lnErr && <>&nbsp;</>}
            </FormGroup>
            <FormGroup marginBottom="24px" display="grid">
              <FormLabel
                marginBottom="8px"
                color="#7D8592"
                fontWeight="600"
                fontSize="14px"
                lineHeight="24px"
                letterSpacing="1px"
              >
                Last Name
              </FormLabel>
              <Input
                placeholder="Last Name"
                value={lastName}
                color="#7D8592"
                type="text"
                onChange={(e) => {
                  if (isNaN(e.target.value) || e.target.value === "") {
                    setLastname(e.target.value);
                    setLnErr(false);
                  }
                }}
              />
              {lnErr && (
                <FormLabel
                  marginBottom="0px"
                  textAlign="left"
                  fontSize="14px"
                  color="red"
                  fontWeight="400"
                  lineHeight="24px"
                  marginTop="5px"
                  fontFamily="inter"
                >
                  Last Name cannot be Empty
                </FormLabel>
              )}
              {fnErr && <>&nbsp;</>}
            </FormGroup>
          </FormGroupInline>

          <FormGroup marginBottom="24px" display="grid" width="100%">
            <FormLabel
              marginBottom="8px"
              color="#7D8592"
              fontWeight="600"
              fontSize="14px"
              lineHeight="24px"
              letterSpacing="1px"
            >
              Email address
            </FormLabel>
            <Input
              placeholder="Enter email"
              disabled
              value={email}
              fontSize="20px"
              fontWeight="500"
              lineHeight="30px"
              color="#667085"
              textAlign="left"
              padding="0px 10px 0px 40px"
            />
            <FaRegUser
              style={{
                position: "absolute",
                left: 15,
                top: "45px",
                cursor: "pointer",
                color: "#7d8592",
                fontSize: 20,
              }}
            />
          </FormGroup>
          <FormGroup
            display="grid"
            width="100%"
            marginRight="23px"
            marginBottom="32px"
          >
            <FormLabel
              color="#7D8592"
              fontWeight="600"
              fontSize="14px"
              lineHeight="24px"
              letterSpacing="1px"
            >
              Provider
            </FormLabel>
            <DropDownStyle marginTop="0px" ref={relationNoteRef}>
              <Div onClick={Toggling6}>
                <SelectInput
                  display="flex"
                  height="48px"
                  background="#FFFFFF"
                  fontSize="14px"
                  name="sex"
                  fontFamily="Inter"
                  fontWeight="400"
                  letterSpacing="0.0025em"
                  color="#747474"
                  border="1px solid #cbd5e0"
                  borderRadius="4px"
                  padding="0px 12px"
                  value={providerNm}
                  style={
                    proErr
                      ? {
                          borderRadius: "4px",
                        }
                      : { borderRadius: "4px" }
                  }
                  id="my_pf-pat_sx"
                >
                  {providerNm || "Select Anyone"}
                  <DropdownImage
                    height="7px"
                    width="10px"
                    right="10px"
                    position="absolute"
                    src={arrowIcon}
                  ></DropdownImage>
                </SelectInput>
              </Div>

              {dropDown6 ? (
                <DropDownDiv
                  width="100%"
                  background="#FFFFFF"
                  border=" 1px solid rgba(46, 46, 46, 0.25)"
                  borderRadius="4px"
                  maxHeight="225px"
                  overflowY="auto"
                >
                  {providersList.map((r, i) => {
                    return (
                      <DropdownOptions
                        fontSize="14px"
                        key={i}
                        value={r.name}
                        padding="10px 12px 8px 12px"
                        onClick={() => relationSelect(r)}
                      >
                        {r.name}
                      </DropdownOptions>
                    );
                  })}
                </DropDownDiv>
              ) : (
                ""
              )}
            </DropDownStyle>
            {proErr && (
              <FormLabel
                marginBottom="0px"
                textAlign="left"
                fontSize="14px"
                color="red"
                fontWeight="400"
                lineHeight="24px"
                marginTop="5px"
                fontFamily="inter"
              >
                Please Select a provider
              </FormLabel>
            )}
          </FormGroup>
          <FormGroup marginBottom="32px" display="grid" width="100%">
            <FormLabel
              marginBottom="8px"
              color="#7D8592"
              fontWeight="600"
              fontSize="14px"
              lineHeight="24px"
              letterSpacing="1px"
            >
              Password
            </FormLabel>
            <Input
              placeholder="Enter password"
              type={PasswordShow ? "password" : "text"}
              value={Password}
              color="#7D8592"
              onChange={(e) => EmailValidation(e)}
              autoComplete="off"
            />
            {PasswordShow ? (
              <FaRegEye
                style={{
                  position: "absolute",
                  right: 15,
                  top: "43px",
                  cursor: "pointer",
                  color: "#7d8592",
                  fontSize: 20,
                }}
                onClick={() => setPasswordShow((Prevstate) => !Prevstate)}
              />
            ) : (
              <FaRegEyeSlash
                style={{
                  position: "absolute",
                  right: 15,
                  top: "43px",
                  cursor: "pointer",
                  color: "#7d8592",
                  fontSize: 20,
                }}
                onClick={() => setPasswordShow((Prevstate) => !Prevstate)}
              />
            )}
            {p1Err && (
              <FormLabel
                marginBottom="0px"
                textAlign="left"
                fontSize="14px"
                color="red"
                fontWeight="400"
                lineHeight="24px"
                marginTop="5px"
                fontFamily="inter"
              >
                {errMsg}
              </FormLabel>
            )}
          </FormGroup>
          <FormGroup marginBottom="32px" display="grid" width="100%">
            <FormLabel
              marginBottom="8px"
              color="#7D8592"
              fontWeight="600"
              fontSize="14px"
              lineHeight="24px"
              letterSpacing="1px"
            >
              Confirm Password
            </FormLabel>
            <Input
              placeholder="Re-Enter password"
              type={PasswordShow1 ? "password" : "text"}
              value={Password1}
              color="#7D8592"
              onChange={(e) => ConformEmail(e)}
              autoComplete="off"
            />
            {PasswordShow1 ? (
              <FaRegEye
                style={{
                  position: "absolute",
                  right: 15,
                  top: "43px",
                  cursor: "pointer",
                  color: "#7d8592",
                  fontSize: 20,
                }}
                onClick={() => setPasswordShow1((Prevstate) => !Prevstate)}
              />
            ) : (
              <FaRegEyeSlash
                style={{
                  position: "absolute",
                  right: 15,
                  top: "43px",
                  cursor: "pointer",
                  color: "#7d8592",
                  fontSize: 20,
                }}
                onClick={() => setPasswordShow1((Prevstate) => !Prevstate)}
              />
            )}
            {p2Err && (
              <FormLabel
                marginBottom="0px"
                textAlign="left"
                fontSize="14px"
                color="red"
                fontWeight="400"
                lineHeight="24px"
                marginTop="5px"
                fontFamily="inter"
              >
                {errMsg}
              </FormLabel>
            )}
          </FormGroup>

          <FormGroup marginBottom="32px" display="grid" width="100%">
            <Button
              background="#2C7BE5"
              hoverBackColor="#005FB2"
              className="save_active"
              padding="18px 140px 18px 140px"
              fontWeight="600"
              fontSize="16px"
              lineHeight="22px"
              letterSpacing="1.1px"
              disabled={btndis}
              onClick={(e) => handleCreate(e)}
            >
              Create Account
            </Button>
          </FormGroup>
          {err && (
            <FormLabel
              marginBottom="0px"
              textAlign="left"
              fontSize="14px"
              color="red"
              fontWeight="400"
              lineHeight="24px"
              marginTop="5px"
              fontFamily="inter"
            >
              {errMsg}
            </FormLabel>
          )}
          <Span
            marginTop="32px"
            color="#667085"
            fontSize="18px"
            lineHeight="26px"
            textAlign="center"
            marginBottom="32px"
          >
            Do you have an account?
            <LinK
              to="/InstantSignIn/Old"
              fontSize="18px"
              lineheight="26px"
              fontFamily="'manrope'"
              onClick={() => handleMode("2")}
            >
              Log in
            </LinK>
          </Span>
        </Form>
      </Div>
    </Container>
  );
};

export default SignUpRight;
